.category{
    color: var(--hiredots-dark);
    transition: all;
}
.category:hover{
    /* background:#efefef !important; */
    background:var(--hiredots-primary) !important;
    color: white !important;
}

.category:hover h3, .category:hover h6{
    color: white;
}