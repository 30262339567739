@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?q5zso7');
  src:  url('fonts/icomoon.eot?q5zso7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?q5zso7') format('truetype'),
    url('fonts/icomoon.woff?q5zso7') format('woff'),
    url('fonts/icomoon.svg?q5zso7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checklist:before {
  content: "\e923";
}
.icon-cooperation:before {
  content: "\e924";
}
.icon-investment:before {
  content: "\e925";
}
.icon-outsourcing:before {
  content: "\e926";
}
.icon-quality:before {
  content: "\e927";
}
.icon-recruitment:before {
  content: "\e928";
}
.icon-recruitment-two:before {
  content: "\e929";
}
.icon-calling:before {
  content: "\e92a";
}
.icon-candidate:before {
  content: "\e92b";
}
.icon-magnifying-glass:before {
  content: "\e900";
}
.icon-open-envelope:before {
  content: "\e901";
}
.icon-right-arrow:before {
  content: "\e902";
}
.icon-up-arrow:before {
  content: "\e903";
}
.icon-down-arrow:before {
  content: "\e904";
}
.icon-left-arrow:before {
  content: "\e905";
}
.icon-report-card:before {
  content: "\e906";
}
.icon-time-check:before {
  content: "\e907";
}
.icon-individual:before {
  content: "\e908";
}
.icon-pdf-file:before {
  content: "\e909";
}
.icon-employment:before {
  content: "\e90a";
}
.icon-teaching:before {
  content: "\e90b";
}
.icon-job:before {
  content: "\e90c";
}
.icon-policy:before {
  content: "\e90d";
}
.icon-controller:before {
  content: "\e90e";
}
.icon-phone-call:before {
  content: "\e90f";
}
.icon-consulting:before {
  content: "\e910";
}
.icon-background-check:before {
  content: "\e911";
}
.icon-save-time:before {
  content: "\e912";
}
.icon-recruitment1:before {
  content: "\e913";
}
.icon-quote:before {
  content: "\e914";
}
.icon-talent-search:before {
  content: "\e915";
}
.icon-creative-idea:before {
  content: "\e916";
}
.icon-mission:before {
  content: "\e917";
}
.icon-solution:before {
  content: "\e918";
}
.icon-trophy:before {
  content: "\e919";
}
.icon-customer-review:before {
  content: "\e91a";
}
.icon-success:before {
  content: "\e91b";
}
.icon-business-idea:before {
  content: "\e91c";
}
.icon-recruit:before {
  content: "\e91d";
}
.icon-telephone:before {
  content: "\e91e";
}
.icon-phone-call-1:before {
  content: "\e91f";
}
.icon-message:before {
  content: "\e920";
}
.icon-placeholder:before {
  content: "\e921";
}
.icon-send:before {
  content: "\e922";
}
